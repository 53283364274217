import ApiService from './api.service';

export const ActionPlanSurveyQuestionService = {
  index(params) {
    return ApiService.query(`model_e/action_plan_survey_questions`, {
      params,
    });
  },
  show(slug) {
    return ApiService.get('model_e/action_plan_survey_questions', slug);
  },
};

export default ActionPlanSurveyQuestionService;
