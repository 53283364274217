import Vue from 'vue';
import HoneybadgerVue from '@honeybadger-io/vue';

if (process.env.VUE_APP_ENV !== 'development') {
  const config = {
    apiKey: process.env.VUE_APP_HONEYBADGER_API_KEY,
    environment: process.env.VUE_APP_ENV,
  };
  Vue.use(HoneybadgerVue, config);
}
