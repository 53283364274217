import axios, { CanceledError } from 'axios';
import JwtService from './jwt.service';

function getFileNameFromContentDispostionHeader(contentDisposition) {
  const standardPattern = /filename=(["']?)(.+)\1/i;
  const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

  if (standardPattern.test(contentDisposition)) {
    return contentDisposition.match(standardPattern)[2];
  }

  if (wrongPattern.test(contentDisposition)) {
    return contentDisposition.match(wrongPattern)[1];
  }
}

const ApiService = {
  init(router) {
    axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}`;
    axios.defaults.headers.common.Accept = 'application/json';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error instanceof CanceledError) {
          return Promise.reject(error);
        }
        console.log(error);
        if (error.response.status === 401) {
          // if the error is 401
          JwtService.destroyToken();

          if (JwtService.getToken() && !JwtService.isTokenExpired()) {
            ApiService.setHeader();
          } else {
            JwtService.destroyToken();
            router.push({ name: 'Login' });
          }
        }

        if (error.response.status === 403) {
          // if the error is 403
          if (JwtService.getRole().indexOf('dealer') !== -1) {
            router.push({ name: 'DealerLanding' });
          } else {
            router.push({ name: 'ServiceCapacityAssessment' });
          }
        }
        return Promise.reject(error);
      }
    );
  },

  setHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${JwtService.getToken()}`;
  },

  unsetHeader() {
    axios.defaults.headers.common.Authorization = null;
  },

  query(resource, params) {
    return axios.get(resource, params).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  // The same as query but without error handling
  // Error handling will be handled by the callee
  search(resource, config) {
    return axios.get(resource, config);
  },

  get(resource, slug = '') {
    return axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  directPost(resource, params) {
    return axios.post(`${resource}`, params);
  },

  directPatch(resource, params) {
    return axios.patch(`${resource}`, params);
  },

  post(resource, params) {
    return axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return axios.patch(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return axios.put(`${resource}`, params);
  },

  delete(resource, id) {
    return axios.delete(`${resource}/${id}`).catch((error) => {
      throw new Error(`ApiService ${error}`);
    });
  },

  destroy(resource) {
    return axios.delete(resource).catch((error) => {
      throw new Error(`ApiService (${resource}) ${error}`);
    });
  },

  async downloadPDF(resource, params) {
    let response;
    try {
      response = await axios({
        url: resource,
        method: 'GET',
        responseType: 'blob',
        params: params,
      });
    } catch (error) {
      throw new Error(
        `ApiService.downloadPDF (${resource} - ${params}) ${error}`
      );
    }
    const serverBlob = await response.data;
    const objectUrl = window.URL.createObjectURL(serverBlob);

    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = getFileNameFromContentDispostionHeader(
      response.headers['content-disposition']
    );
    link.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(objectUrl);
    }, 0);
  },
};

export default ApiService;
