const ID_TOKEN_KEY = 'fscst_id_token';
const USER_UUID_KEY = 'user_uuid';
const USER_COUNTRY_KEY = 'user_country';
const SESSION_UUID_KEY = 'session_uuid';
const USER_ID_KEY = 'user_id';
const REDIRECT_RESOURCE_UUID = 'resource_uuid';
const USER_ROLE = 'user_role';

const JwtService = {
  getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  },

  saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  },

  saveRole(role) {
    window.localStorage.setItem(USER_ROLE, role);
  },

  getRole() {
    return window.localStorage.getItem(USER_ROLE);
  },

  destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(USER_UUID_KEY);
    window.localStorage.removeItem(USER_COUNTRY_KEY);
    window.localStorage.removeItem(SESSION_UUID_KEY);
    window.localStorage.removeItem(USER_ID_KEY);
    window.localStorage.removeItem(USER_ROLE);
  },

  getUserUuid() {
    return window.localStorage.getItem(USER_UUID_KEY);
  },

  saveUserUuid(uuid) {
    window.localStorage.setItem(USER_UUID_KEY, uuid);
  },

  getUserCountry() {
    return window.localStorage.getItem(USER_COUNTRY_KEY);
  },

  saveUserCountry(country) {
    window.localStorage.setItem(USER_COUNTRY_KEY, country);
  },

  saveSessionUuid(uuid) {
    window.localStorage.setItem(SESSION_UUID_KEY, uuid);
  },

  getSessionUuid() {
    return window.localStorage.getItem(SESSION_UUID_KEY);
  },

  saveUserId(id) {
    window.localStorage.setItem(USER_ID_KEY, id);
  },

  getUserId() {
    return window.localStorage.getItem(USER_ID_KEY);
  },

  saveRedirectResourceUuid(uuid) {
    window.localStorage.setItem(REDIRECT_RESOURCE_UUID, uuid);
  },

  getRedirectResourceUuid() {
    return window.localStorage.getItem(REDIRECT_RESOURCE_UUID);
  },

  clearRedirectResourceUuid() {
    window.localStorage.removeItem(REDIRECT_RESOURCE_UUID);
  },

  parseJwt() {
    const token = this.getToken();
    if (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
    }
    return null;
  },

  isTokenExpired() {
    const token = this.parseJwt();
    const now = Math.floor(Date.now() / 1000);
    // console.log("This is the token:");
    // console.log(token);
    return token !== null && token.exp < now;
  },
};

export default JwtService;
