import ApiService from './api.service';

export const DealerService = {
  index(params) {
    return ApiService.query('dealers', {
      params,
    });
  },
  searchIndex(params, signal) {
    return ApiService.search('dealers', {
      params,
      signal,
    });
  },
  show(slug) {
    return ApiService.get('dealers', slug);
  },
  create(params) {
    return ApiService.post('dealers', { dealer: params });
  },
  update(slug, params) {
    return ApiService.update('dealers', slug, { dealer: params });
  },
  destroy(slug) {
    return ApiService.delete('dealers', slug);
  },
  getMarketAreas(params, signal = null) {
    return ApiService.search('dealers/get_all_market_areas', {
      params,
      signal,
    });
  },
  getRegions(params, signal = null) {
    return ApiService.search('dealers/get_all_regions', {
      params,
      signal,
    });
  },
  getZones(params, signal = null) {
    return ApiService.search('dealers/get_all_zones', {
      params,
      signal,
    });
  },
};

export default DealerService;
