import ApiService from './api.service';

export const ModelEAssessmentService = {
  index(params) {
    return ApiService.query('model_e/assessments', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('model_e/assessments', slug);
  },
  create(params) {
    return ApiService.post('model_e/assessments', { data: params });
  },
  update(slug, params) {
    return ApiService.update('model_e/assessments', slug, { data: params });
  },
  destroy(slug) {
    return ApiService.delete('model_e/assessments', slug);
  },
  generateEnhancedReport(params) {
    return ApiService.downloadFile('model_e/assessments/enhanced_reporting', {
      params,
    });
  },
  csvExport(params) {
    return ApiService.downloadFile('model_e/assessments/csv_export', {
      params,
    });
  },
};

export default ModelEAssessmentService;
