import ApiService from '@/services/api.service';

export const RecallAnswerService = {
  index(params) {
    return ApiService.query('recall/answers', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('recall/answers', slug);
  },
  create(params) {
    return ApiService.post('recall/answers', {
      data: params,
    });
  },
  update(slug, params) {
    return ApiService.update('recall/answers', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('recall/answers', slug);
  },
};

export default RecallAnswerService;
