import ApiService from '@/services/api.service';

export const RecallActionPlanService = {
  index(params) {
    return ApiService.query('recall/action_plans', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('recall/action_plans', slug);
  },
  create(params) {
    return ApiService.post('recall/action_plans', {
      data: params,
    });
  },
  update(slug, params) {
    return ApiService.update('recall/action_plans', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('recall/action_plans', slug);
  },
};

export default RecallActionPlanService;
