import router from '@/router';
import { omit } from 'lodash/fp';
import ModelEComplianceActionPlanService from '../../services/model-e-compliance-action-plan.service';
import ModelEComplianceMetricAnswerService from '../../services/model-e-compliance-metric-answer.service';
import ModelEComplianceMetricService from '../../services/model-e-compliance-metric.service';
import ModelEComplianceMetricAnswerMeasureService from '../../services/model-e-compliance-metric-answer-measure.service';
import ModelEComplianceMetricAnswerPersonService from '../../services/model-e-compliance-metric-answer-person.service';
import ModelEActionPlanService from '@/services/model-e-action-plan.service';
import DealerService from '@/services/dealer.service';
import ModelEAssessmentService from '@/services/model-e-assessment.service';

function complianceRequired(category) {
  return category.complianceRequired;
}

function complianceNotRequired(category) {
  return !category.complianceRequired;
}

function categoryMap(state, categoryName) {
  return state.complianceMetricCategories.categories[categoryName];
}

export default {
  namespaced: true,
  state: {
    currentAssessment: null,
    currentDealership: null,
    complianceMetricAnswers: {
      byId: {},
      allIds: [],
    },
    complianceMetrics: {
      byId: {},
      allIds: [],
    },
    complianceMetricCategories: {
      order: [],
      categories: {},
    },
    complianceMetricActionPlans: {
      byId: {},
      allIds: [],
    },
    complianceMetricAnswerMeasures: {
      byId: {},
      allIds: [],
    },
    complianceMetricAnswerPeople: {
      byId: {},
      allIds: [],
    },
    complianceMetricAdditionalInputs: {
      byAnswerId: {},
      allAnswerIds: [],
    },
    actionPlans: {
      byId: {},
      allIds: [],
    },
  },
  actions: {
    async getDealershipById({ commit }, dealershipId) {
      const response = await DealerService.show(dealershipId);
      commit('setCurrentDealership', response.data);
    },
    async getModelEAssessmentById({ commit }, assessmentId) {
      const response = await ModelEAssessmentService.show(assessmentId);
      commit('setCurrentAssessment', response.data);
    },
    async fetchComplianceMetrics(
      { dispatch, commit },
      { perPage, assessmentId }
    ) {
      const answerParams = {
        'q[model_e_assessment_id_eq]': assessmentId,
        'q[archived_eq]': false,
        per_page: perPage,
      };
      const metricParams = {
        per_page: perPage,
        'q[archived_eq]': false,
      };
      await dispatch('fetchComplianceMetricAnswers', answerParams);
      const response = await ModelEComplianceMetricService.index(metricParams);
      commit('updateComplianceMetrics', response.data);
      commit('updateComplianceMetricCategories');
    },
    async fetchComplianceMetricAnswers({ commit }, metricParams) {
      const response = await ModelEComplianceMetricAnswerService.index(
        metricParams
      );
      commit(
        'updateComplianceMetricAnswers',
        // To avoid using the nested data, they should be omitted from the store.
        // NOTE: The data stored in these properties are placed into their
        // own state buckets
        response.data.map(
          omit([
            'action_item',
            'measure',
            'people',
            'additional_data',
            'additional_inputs',
          ])
        )
      );
      commit(
        'updateComplianceActionPlans',
        response.data
          .map((answer) => answer.action_item)
          .filter((actionPlan) => actionPlan != null)
      );
      commit(
        'updateAnswerMeasures',
        response.data
          .map((answer) => answer.measure)
          .filter((measure) => measure != null)
      );
      commit(
        'updateAnswerPeople',
        response.data
          .map((answer) => answer.people)
          .filter((people) => people != null || people.length > 0)
      );
      commit(
        'updateAnswerAdditionalInputs',
        response.data
          .map((answer) => ({
            answer_id: answer.id,
            additionalInputs: answer.additional_inputs,
          }))
          .filter(
            (inputData) =>
              inputData.additionalInputs != null &&
              Object.keys(inputData.additionalInputs).length > 0
          )
      );
    },
    async fetchComplianceMetricAnswer({ commit }, answerId) {
      const response = await ModelEComplianceMetricAnswerService.show(answerId);
      commit('updateComplianceMetricAnswer', response.data);
      if (response.data.action_item) {
        commit('updateComplianceActionPlan', response.data.action_item);
      }
      if (response.data.additional_data) {
        commit('updateAnswerAdditionalInput', {
          answer_id: response.data.id,
          additionalInputs: response.data.additional_inputs,
        });
      }
    },
    async createOrUpdateAnswerPlan(
      { commit },
      { actionPlanId, answerId, data }
    ) {
      const params = {
        model_e_compliance_metric_answer_id: answerId,
        person_who: data.who,
        person_what: data.what,
        person_when: data.when,
      };
      const response = actionPlanId
        ? await ModelEComplianceActionPlanService.update(actionPlanId, params)
        : await ModelEComplianceActionPlanService.create(params);

      commit('updateComplianceActionPlan', response.data);
    },
    async createOrUpdateAnswerMeasure(
      { commit },
      { measureId, answerId, data }
    ) {
      const params = {
        model_e_compliance_metric_answer_id: answerId,
        measure: data,
      };
      const response = measureId
        ? await ModelEComplianceMetricAnswerMeasureService.update(
            measureId,
            params
          )
        : await ModelEComplianceMetricAnswerMeasureService.create(params);

      commit('updateAnswerMeasure', response.data);
    },
    async createOrUpdateAnswerPerson({ commit }, { personId, answerId, data }) {
      const params = {
        model_e_compliance_metric_answer_id: answerId,
        person_name: data.name,
        person_phone: data.phone,
        person_title: data.title,
        person_email: data.email,
      };
      const response = personId
        ? await ModelEComplianceMetricAnswerPersonService.update(
            personId,
            params
          )
        : await ModelEComplianceMetricAnswerPersonService.create(params);

      commit('updateAnswerPerson', response.data);
    },
    async pushUpdatedAnswerAdditionalInputs({ commit }, { answer_id, data }) { // eslint-disable-line
      const params = {
        additional_data: data,
      };
      await ModelEComplianceMetricAnswerService.update(answer_id, params);
    },
    async fetchActionPlans({ commit }, { assessmentId, perPage }) {
      const params = {
        'q[model_e_assessment_id_eq]': assessmentId,
        'q[archived_eq]': false,
        per_page: perPage ?? 1,
      };
      const response = await ModelEActionPlanService.index(params);
      commit('updateActionPlans', response.data);
    },
    async updateDealerNotes({ commit }, { assessmentId, notes }) {
      const response = await ModelEAssessmentService.update(assessmentId, {
        dealer_notes: notes,
      });
      commit('setCurrentAssessment', response.data);
    },
  },
  getters: {
    getDealerNotesFromAssessment(state) {
      return state.currentAssessment?.dealer_notes ?? null;
    },
    getComplianceMetricAnswerPoepleByAnswerId(state) {
      return (answerId) =>
        Object.values(state.complianceMetricAnswerPeople.byId).filter(
          (person) => person.model_e_compliance_metric_answer_id === answerId
        );
    },
    getComplianceMetricActionPlanByAnswerId(state) {
      return (answerId) =>
        Object.values(state.complianceMetricActionPlans.byId).find(
          (actionPlan) =>
            actionPlan.model_e_compliance_metric_answer_id === answerId
        );
    },
    getComplianceMetricAnswerMeasureByAnswerId(state) {
      return (answerId) =>
        Object.values(state.complianceMetricAnswerMeasures.byId).find(
          (measure) => measure.model_e_compliance_metric_answer_id === answerId
        );
    },
    getMetricsByCategory(state) {
      return state.complianceMetricCategories.order.map((categoryName) => [
        categoryName,
        state.complianceMetricCategories.categories[categoryName],
      ]);
    },
    getFirstCategory(state) {
      // This should only contain the first assessment category
      // which should only contain one assessment
      return state.complianceMetricCategories.categories[
        state.complianceMetricCategories.order[0]
      ];
    },
    getComplianceRequiredCategoryNames(_, getters) {
      return getters.getComplianceRequiredCategories.map(
        ({ categoryName }) => categoryName
      );
    },
    getComplianceRequiredCategories(state) {
      const categoryNameMap = categoryMap.bind(null, state);
      return state.complianceMetricCategories.order
        .map(categoryNameMap)
        .filter((cat) => cat.categoryKey !== 'ATCA') //ignore the attestation category
        .filter(complianceRequired);
    },
    getRegularCategories(state) {
      const categoryNameMap = categoryMap.bind(null, state);
      return state.complianceMetricCategories.order
        .map(categoryNameMap)
        .filter((cat) => cat.categoryKey !== 'ATCA') //ignore the attestation category
        .filter(complianceNotRequired);
    },
    getComplianceMetricAnswerById(state) {
      return (id) => state.complianceMetricAnswers.byId[id];
    },
    getComplianceMetricAdditionalInputsByAnswerId(state) {
      return (answerId) =>
        state.complianceMetricAdditionalInputs.byAnswerId[answerId];
    },
    getComplianceMetricById(state) {
      return (id) => state.complianceMetrics.byId[id];
    },
    getComplianceMetricAnswerByMetricId(state) {
      return (id) =>
        state.complianceMetricAnswers.allIds
          .map((id) => state.complianceMetricAnswers.byId[id])
          .find((answer) => answer.model_e_compliance_metric_id === id);
    },
    getComplianceMetricAndAnwserByIds(_, getters) {
      return (metricIds) =>
        metricIds.map((id) => [
          getters.getComplianceMetricById(id),
          getters.getComplianceMetricAnswerByMetricId(id),
        ]);
    },
    getActionPlansByAssessmentId(state) {
      return (assessmentId) =>
        state.actionPlans.allIds
          .map((id) => state.actionPlans.byId[id])
          .filter(
            (actionPlan) => actionPlan.model_e_assessment_id === assessmentId
          );
    },
  },
  mutations: {
    updateActionPlans(state, actionPlans) {
      state.actionPlans.allIds = actionPlans.map((actionPlan) => actionPlan.id);
      state.actionPlans.byId = actionPlans.reduce(
        (actionPlansById, actionPlan) => ({
          ...actionPlansById,
          [actionPlan.id]: actionPlan,
        }),
        {}
      );
    },
    updateAnswerPeople(state, people) {
      state.complianceMetricAnswerPeople.allIds = [
        ...state.complianceMetricAnswerPeople.allIds,
        ...people
          .flat()
          .filter(
            (person) =>
              !state.complianceMetricAnswerPeople.allIds.includes(person.id)
          )
          .map((person) => person.id),
      ];
      state.complianceMetricAnswerPeople.byId = people.flat().reduce(
        (peopleById, person) => ({
          ...peopleById,
          [person.id]: person,
        }),
        { ...state.complianceMetricAnswerPeople.byId }
      );
    },
    updateAnswerPerson(state, person) {
      if (!state.complianceMetricAnswerPeople.allIds.includes(person.id)) {
        state.complianceMetricAnswerPeople.allIds = [
          ...state.complianceMetricAnswerPeople.allIds,
          person.id,
        ];
      }
      state.complianceMetricAnswerPeople.byId = {
        ...state.complianceMetricAnswerPeople.byId,
        [person.id]: person,
      };
    },
    updateAnswerMeasures(state, measures) {
      state.complianceMetricAnswerMeasures.allIds = measures.map(
        (measure) => measure.id
      );
      state.complianceMetricAnswerMeasures.byId = measures.reduce(
        (measuresById, measure) => ({
          ...measuresById,
          [measure.id]: measure,
        }),
        {}
      );
    },
    updateAnswerMeasure(state, measure) {
      if (!state.complianceMetricAnswerMeasures.allIds.includes(measure.id)) {
        state.complianceMetricAnswerMeasures.allIds = [
          ...state.complianceMetricAnswerMeasures.allIds,
          measure.id,
        ];
      }
      state.complianceMetricAnswerMeasures.byId = {
        ...state.complianceMetricAnswerMeasures.byId,
        [measure.id]: measure,
      };
    },
    updateComplianceActionPlans(state, actionPlans) {
      state.complianceMetricActionPlans.allIds = actionPlans.map(
        (actionPlan) => actionPlan.id
      );
      state.complianceMetricActionPlans.byId = actionPlans.reduce(
        (actionPlansById, actionPlan) => ({
          ...actionPlansById,
          [actionPlan.id]: actionPlan,
        }),
        {}
      );
    },
    updateComplianceActionPlan(state, actionPlan) {
      if (!state.complianceMetricActionPlans.allIds.includes(actionPlan.id)) {
        state.complianceMetricActionPlans.allIds = [
          ...state.complianceMetricActionPlans.allIds,
          actionPlan.id,
        ];
      }
      state.complianceMetricActionPlans.byId = {
        ...state.complianceMetricActionPlans.byId,
        [actionPlan.id]: actionPlan,
      };
    },
    updateComplianceMetricAnswers(state, answers) {
      const ids = [];
      answers.forEach((answer) => {
        state.complianceMetricAnswers.byId[answer.id] = answer;
        ids.push(answer.id);
      });
      state.complianceMetricAnswers.allIds = ids;
    },
    updateComplianceMetricAnswer(state, updatedAnswer) {
      if (!state.complianceMetricAnswers.allIds.includes(updatedAnswer.id)) {
        state.complianceMetricAnswers.allIds = [
          ...state.complianceMetricAnswers.allIds,
          updatedAnswer.id,
        ];
      }
      // Need to update all records for byId in order for vuex to know that
      // one of them has changed. This is because only the known properties are
      // reactive since the data was not loaded yet.
      // For example, the following bit of code will not work
      // state.complianceMetricAnswers.byId[updatedAnswer.id] = updatedAnswer;
      state.complianceMetricAnswers.byId = {
        ...state.complianceMetricAnswers.byId,
        [updatedAnswer.id]: updatedAnswer,
      };
    },
    updateAnswerAdditionalInputs(state, additionalInputData) {
      state.complianceMetricAdditionalInputs.allAnswerIds = [
        ...state.complianceMetricAdditionalInputs.allAnswerIds,
        ...additionalInputData
          .flat()
          .filter(
            (additionalInputDatum) =>
              !state.complianceMetricAdditionalInputs.allAnswerIds.includes(
                additionalInputDatum.answer_id
              )
          )
          .map((additionalInputDatum) => additionalInputDatum.answer_id),
      ];
      state.complianceMetricAdditionalInputs.byAnswerId = additionalInputData
        .flat()
        .reduce(
          (inputDataById, inputData) => ({
            ...inputDataById,
            [inputData.answer_id]: inputData.additionalInputs,
          }),
          { ...state.complianceMetricAdditionalInputs.byAnswerId }
        );
    },
    updateAnswerAdditionalInput(state, additionalInputDatum) {
      if (
        !state.complianceMetricAdditionalInputs.allAnswerIds.includes(
          additionalInputDatum.answer_id
        )
      ) {
        state.complianceMetricAdditionalInputs.allAnswerIds = [
          ...state.complianceMetricAdditionalInputs.allAnswerIds,
          additionalInputDatum.answer_id,
        ];
      }
      state.complianceMetricAdditionalInputs.byAnswerId = {
        ...state.complianceMetricAdditionalInputs.byAnswerId,
        [additionalInputDatum.answer_id]: additionalInputDatum.additionalInputs,
      };
    },
    updateComplianceMetrics(state, metrics) {
      const ids = [];
      metrics.forEach((metric) => {
        state.complianceMetrics.byId[metric.id] = metric;
        ids.push(metric.id);
      });
      state.complianceMetrics.allIds = ids;
    },
    updateComplianceMetricCategories(state) {
      if (state.complianceMetrics.allIds.length === 0) {
        return null;
      }

      const categories = state.complianceMetrics.allIds
        .map((id) => {
          const metric = state.complianceMetrics.byId[id];
          return {
            category: metric.category,
            categoryKey: metric.category_key,
            categoryOrder: metric.category_order,
            metricId: metric.id,
            metricOrder: metric.question_order,
            complianceRequired: metric.compliance_required,
          };
        })
        .reduce(
          (metricsByCategory, metricIdWithCategory) => ({
            ...metricsByCategory,
            [metricIdWithCategory.category]: {
              order: metricIdWithCategory.categoryOrder,
              metricIds: [
                ...(metricsByCategory[metricIdWithCategory.category]
                  ? metricsByCategory[metricIdWithCategory.category].metricIds
                  : []),
                [
                  metricIdWithCategory.metricId,
                  metricIdWithCategory.metricOrder,
                ],
              ],
              categoryName: metricIdWithCategory.category,
              categoryKey: metricIdWithCategory.categoryKey,
              complianceRequired: metricIdWithCategory.complianceRequired,
            },
          }),
          {}
        );

      state.complianceMetricCategories.order = Object.entries(categories)
        .sort(([, a], [, b]) => a.order - b.order)
        .map(([name]) => name);

      state.complianceMetricCategories.categories = Object.entries(
        categories
      ).reduce(
        (
          orderedMetricByCategory,
          [name, { metricIds, categoryName, categoryKey, complianceRequired }]
        ) => ({
          ...orderedMetricByCategory,
          [name]: {
            metricIds: metricIds
              .sort(([, a], [, b]) => a - b)
              .map(([id]) => id),
            categoryName,
            categoryKey,
            complianceRequired,
          },
        }),
        {}
      );
    },
    setCurrentAssessment(state, assessment) {
      state.currentAssessment = assessment;
    },
    setCurrentDealership(state, dealership) {
      if (state.currentDealership !== null && dealership === null) {
        router.push({ name: 'ModelE_DealershipSelection' });
      }
      state.currentDealership = dealership;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
