import ApiService from '@/services/api.service';

export const RecallAnswerPersonService = {
  index(params) {
    return ApiService.query('recall/answer_people', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('recall/answer_people', slug);
  },
  create(params) {
    return ApiService.post('recall/answer_people', {
      data: params,
    });
  },
  update(slug, params) {
    return ApiService.update('recall/answer_people', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('recall/answer_people', slug);
  },
};

export default RecallAnswerPersonService;
