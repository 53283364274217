import ApiService from './api.service';

export const ModelEComplianceMetricAnswerService = {
  index(params) {
    return ApiService.query('model_e/compliance_metric_answers', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('model_e/compliance_metric_answers', slug);
  },
  create(params) {
    return ApiService.post('model_e/compliance_metric_answers', {
      data: params,
    });
  },
  update(slug, params) {
    return ApiService.update('model_e/compliance_metric_answers', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('model_e/compliance_metric_answers', slug);
  },
};

export default ModelEComplianceMetricAnswerService;
