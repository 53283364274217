import ApiService from '@/services/api.service';

export const RecallQuestionService = {
  index(params) {
    return ApiService.query('recall/questions', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('recall/questions', slug);
  },
  create(params) {
    return ApiService.post('recall/questions', { data: params });
  },
  update(slug, params) {
    return ApiService.update('recall/questions', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('recall/questions', slug);
  },
};

export default RecallQuestionService;
