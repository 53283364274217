import ApiService from '@/services/api.service';

export const RecallAssessmentService = {
  index(params) {
    return ApiService.query('recall/assessments', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('recall/assessments', slug);
  },
  create(params) {
    return ApiService.post('recall/assessments', { data: params });
  },
  update(slug, params) {
    return ApiService.update('recall/assessments', slug, { data: params });
  },
  destroy(slug) {
    return ApiService.delete('recall/assessments', slug);
  },
  csvExport(params) {
    return ApiService.downloadFile('recall/assessments/csv_export', {
      params,
    });
  },
  excelExport(params) {
    return ApiService.downloadFile('recall/assessments/excel_export', {
      params,
    });
  },
};

export default RecallAssessmentService;
