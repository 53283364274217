import router from '@/router';

export default {
  namespaced: true,
  state: {
    currentAssessment: null,
    currentDealership: null,
    reviewing: false,
    trt: [],
  },
  mutations: {
    setReviewing(state, reviewing) {
      state.reviewing = reviewing;
    },
    setCurrentAssessment(state, assessment) {
      state.currentAssessment = assessment;
    },
    setCurrentDealership(state, dealership) {
      if (state.currentDealership !== null && dealership === null) {
        router.push({ name: 'DealershipSelection' });
        this.setTRT(null);
      }
      // localStorage.setItem('dealership', JSON.stringify(dealership))
      state.currentDealership = dealership;
    },
    setTRT(state, trt) {
      state.trt = trt;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
