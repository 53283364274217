function isNumericAnswer(type) {
  return ['number', 'percentage', 'money', 'integer'].includes(type);
}

function isBooleanAnswer(type) {
  return type === 'boolean';
}

function isPersonAnswer(type) {
  return type === 'person';
}

function isTextAnswer(type) {
  return type === 'text';
}

const BOOLEAN_ANSWERS = ['1', 't', 'T', 'true', 'True', 'TRUE'];

function initMeasureData(type, measure, answerId, getters) {
  if (isBooleanAnswer(type)) {
    return BOOLEAN_ANSWERS.includes(measure?.measure);
  }

  if (isNumericAnswer(type)) {
    return measure ? `${measure.measure}` : '';
  }

  if (isTextAnswer(type)) {
    return measure ? measure.measure : '';
  }

  if (isPersonAnswer(type)) {
    const people =
      getters['modelE/getComplianceMetricAnswerPoepleByAnswerId'](answerId);
    return people.map((person) => ({
      name: person.person_name,
      phone: person.person_phone,
      title: person.person_title,
      email: person.person_email,
      id: person.id,
    }));
  }

  return null;
}

export const EXCLUSIONS = ['KLAM-DDMS'];

export default {
  namespaced: true,
  state: {
    formStates: {},
    measures: {},
    actionPlans: {},
  },
  actions: {
    initForms({ commit, rootGetters }, forms) {
      const states = forms.reduce(
        (states, { index, type, metricKey, metricId }) => {
          const answer =
            rootGetters['modelE/getComplianceMetricAnswerByMetricId'](metricId);
          const measure = rootGetters[
            'modelE/getComplianceMetricAnswerMeasureByAnswerId'
          ](answer.id);
          const actionPlan = rootGetters[
            'modelE/getComplianceMetricActionPlanByAnswerId'
          ](answer.id);

          if (answer == null) {
            console.error(
              `Skipping ${metricKey} because of missing data that is expected to exist`
            );
            return states;
          }

          return {
            formStates: {
              ...states.formStates,
              [metricId]: {
                index,
                type,
                metricId,
                dirty: false,
                changed: false,
                // valid is null here because at first we don't know if the observer
                // is value or not. It's not safe to assume false or true until the
                // ValidationDispatcher calls the setValidObserver action
                valid: null,
                // The exclusion metric should always be updatable
                allowMeasureUpdate:
                  EXCLUSIONS.includes(metricKey) || !answer.pre_populated,
              },
            },
            measures: {
              ...states.measures,
              [metricId]: {
                metricId,
                answerId: answer.id,
                measureId: measure?.id ?? null,
                measure: initMeasureData(type, measure, answer.id, rootGetters),
              },
            },
            actionPlans: {
              ...states.actionPlans,
              [metricId]: {
                metricId,
                actionPlanId: actionPlan?.id ?? null,
                answerId: answer.id,
                actionPlan: {
                  who: actionPlan?.person_who ?? '',
                  what: actionPlan?.person_what ?? '',
                  when: actionPlan?.person_when ?? '',
                },
              },
            },
          };
        },
        { formStates: {}, measures: {}, actionPlans: {} }
      );
      commit('initializeFormStates', states.formStates);
      commit('initializeMeasures', states.measures);
      commit('initializeActionPlans', states.actionPlans);
    },
    wipeAllState({ commit }) {
      commit('wipeAllState');
    },
    setDirtyForm({ commit }, { metricId, value }) {
      commit('updateForm', {
        metricId,
        newState: { dirty: value },
      });
    },
    setValidForm({ commit }, { metricId, value }) {
      commit('updateForm', {
        metricId,
        newState: { valid: value },
      });
    },
    setChangedForm({ commit }, { metricId, value }) {
      commit('updateForm', {
        metricId,
        newState: { changed: value },
      });
    },
    updateFormMeasureByMetricId({ commit }, { metricId, measure }) {
      commit('updateMeasure', {
        metricId,
        newState: { measure },
      });
    },
    updateFormActionPlanByMetricId({ commit }, { metricId, actionPlan }) {
      commit('updateActionPlan', {
        metricId,
        newState: { actionPlan },
      });
    },
    async syncChanged({ dispatch, state }) {
      const changedForms = Object.values(state.formStates).filter(
        (form) => form.changed
      );
      for (let form of changedForms) {
        await dispatch('syncData', {
          form,
          measure: state.measures[form.metricId],
          actionPlan: state.actionPlans[form.metricId],
        });
      }
    },
    async syncData({ dispatch, rootState }, { form, measure, actionPlan }) {
      await dispatch(
        'modelE/createOrUpdateAnswerPlan',
        {
          actionPlanId: actionPlan.actionPlanId,
          data: {
            who: actionPlan.actionPlan.who,
            what: actionPlan.actionPlan.what,
            when: actionPlan.actionPlan.when,
          },
          answerId: actionPlan.answerId,
        },
        { root: true }
      );
      if (form.allowMeasureUpdate) {
        if (isPersonAnswer(form.type)) {
          const answerId = measure.answerId;
          await Promise.all(
            measure.measure.map((person) =>
              dispatch(
                'modelE/createOrUpdateAnswerPerson',
                {
                  personId: person.id,
                  answerId,
                  data: person,
                },
                { root: true }
              )
            )
          );
        } else {
          await dispatch(
            'modelE/createOrUpdateAnswerMeasure',
            {
              measureId: measure.measureId,
              answerId: measure.answerId,
              data: measure.measure,
            },
            { root: true }
          );
        }
      }
      if (
        Object.prototype.hasOwnProperty.call(
          rootState.modelE.complianceMetricAdditionalInputs.byAnswerId,
          measure.answerId
        )
      ) {
        await dispatch(
          'modelE/pushUpdatedAnswerAdditionalInputs',
          {
            answer_id: measure.answerId,
            data: rootState.modelE.complianceMetricAdditionalInputs.byAnswerId[
              measure.answerId
            ],
          },
          {
            root: true,
          }
        );
      }
      await dispatch('modelE/fetchComplianceMetricAnswer', measure.answerId, {
        root: true,
      });
    },
  },
  getters: {
    getMeasureByMetricId(state) {
      return (metricId) => state.measures[metricId];
    },
    getActionPlanByMetricId(state) {
      return (metricId) => state.actionPlans[metricId];
    },
    getFormStateByMetricId(state) {
      return (metricId) => state.formStates[metricId];
    },
    getUnsavedChangesCount(state) {
      return Object.values(state.formStates)
        .filter((form) => form.changed)
        .reduce((count) => count + 1, 0);
    },
    getInvalidCount(state) {
      // Need to ONLY filter for invalid observers
      // This needs to be explicit because the valid property can be
      // null. A null value in this case does not mean the observer
      // is invalid!
      return Object.values(state.formStates)
        .filter((form) => form.valid === false) // explicit false check
        .reduce((count) => count + 1, 0);
    },
    getInvalidForms(state) {
      return Object.values(state.formStates)
        .filter((form) => form.valid === false) // explicit false check
        .map((form) => form.index);
    },
  },
  mutations: {
    wipeAllState(state) {
      state.formStates = {};
      state.measures = {};
      state.actionPlans = {};
    },
    initializeFormStates(state, forms) {
      state.formStates = { ...forms };
    },
    initializeMeasures(state, measures) {
      state.measures = { ...measures };
    },
    initializeActionPlans(state, actionPlans) {
      state.actionPlans = { ...actionPlans };
    },
    updateActionPlan(state, { metricId, newState }) {
      state.actionPlans[metricId] = {
        ...state.actionPlans[metricId],
        ...newState,
      };
    },
    updateMeasure(state, { metricId, newState }) {
      state.measures[metricId] = {
        ...state.measures[metricId],
        ...newState,
      };
    },
    updateForm(state, { metricId, newState }) {
      state.formStates[metricId] = {
        ...state.formStates[metricId],
        ...newState,
      };
    },
  },
};
