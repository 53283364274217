import { extend } from 'vee-validate';
import { between, email, max, min, required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import { isMatch } from 'date-fns';

/******************
 * Included Rules
 ******************/
extend('required', { ...required, message: messages['required'] });
extend('max', { ...max, message: messages['max'] });
extend('min', { ...min, message: messages['min'] });
extend('between', { ...between, message: messages['between'] });
extend('email', { ...email, message: messages['email'] });

/******************
 * Custom Rules
 ******************/
extend('date', {
  validate(value) {
    return isMatch(value, 'yyyy-MM-dd');
  },
  message: 'The {_field_} field must be a valid date',
});

const NA_PHONE_NUMBER = new RegExp(
  '^\\(?([0-9]{3})\\)?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$'
);
extend('na_phone_number', {
  validate(value) {
    return NA_PHONE_NUMBER.test(value);
  },
  message: 'The {_field_} field must be a valid phone number',
});
