import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import Vuex from 'vuex';
import Notifications from 'vue-notification';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './styles/fontawesome_v5.13.0_all.css';

import router from './router';
import store from './store';

import './honeybadger';

import { BootstrapVue } from 'bootstrap-vue';
import vSelect from 'vue-select';
import ApiService from './services/api.service';

// css
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-select/dist/vue-select.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import i18n from './i18n';

library.add(fal);
library.add(far);
library.add(fas);

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from '@fortawesome/vue-fontawesome';
Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('FontAwesomeLayers', FontAwesomeLayers);

import { ToggleButton } from 'vue-js-toggle-button';
Vue.component('ToggleButton', ToggleButton);

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
});

/* Google Analytics */
import VueGtag from 'vue-gtag';
if (process.env.VUE_APP_ENV !== 'development') {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG },
    },
    router
  );
}
/* Google Analytics */

Vue.use(BootstrapVue);
Vue.use(Vuex);
Vue.use(Notifications);
Vue.use(VueSweetalert2);
Vue.component('VSelect', vSelect);

// Vue MQ (MediaQuery) https://github.com/AlexandreBonaventure/vue-mq
import VueMq from 'vue-mq';

Vue.use(VueMq, {
  breakpoints: {
    // default breakpoints - customize this
    sm: 576,
    md: 768,
    lg: 992,
    xl: Infinity,
  },
});

let enableDevTools = false;
let enablePerformanceMode = true;
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'qa') {
  enableDevTools = true;
  enablePerformanceMode = false;
}

Vue.config.devtools = enableDevTools;
Vue.config.performance = enablePerformanceMode;

import './validationRules';

const vueApp = new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
});

ApiService.init(router);

export default vueApp.$mount('#app');
