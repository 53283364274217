import ActionPlanSurveyQuestionService from '@/services/action_plan_survey_question.service';
import ActionPlanSurveyService from '@/services/action_plan_survey.service';

export default {
  namespaced: true,
  state: {
    survey: {},
    acknowledgement: false,
    questions: {
      byId: {},
      allIds: [],
    },
    answers: {
      byQuestionId: {},
    },
  },
  actions: {
    async fetchQuestions({ commit }, { country }) {
      const response = await ActionPlanSurveyQuestionService.index({
        'q[country_eq]': country,
      });
      response.data.forEach((question) => {
        question.status = true;
      });
      commit('updateQuestions', response.data);
    },
    async fetchSurvey({ commit }, { assessmentId }) {
      const response = await ActionPlanSurveyService.getOrCreate(assessmentId);
      commit('updateSurvey', response.data);
    },
    async addSurveyAnswers({ commit }, { assessmentId, data }) {
      const response = await ActionPlanSurveyService.addSurveyAnswers(
        assessmentId,
        data
      );
      commit('updateSurvey', response.data);
    },
    setAnswerForQuestion({ commit }, { questionId, answer }) {
      commit('updateAnswerByQuestionId', { questionId, answer });
    },
    setAcknowledgement({ commit }, { acknowledgement }) {
      commit('updateAcknowledgement', acknowledgement);
    },
  },
  getters: {
    questions(state) {
      return state.questions.allIds.map(
        (questionId) => state.questions.byId[questionId]
      );
    },
    survey(state) {
      return state.survey;
    },
    acknowledgement(state) {
      return state.acknowledgement;
    },
    getAnswerByQuestionId(state) {
      return (questionId) => state.answers.byQuestionId[questionId] ?? null;
    },
    getAnswers(state) {
      return Object.entries(state.answers.byQuestionId).reduce(
        (answers, [questionId, answer]) => [
          ...answers,
          {
            model_e_action_plan_survey_id: state.survey.id,
            model_e_action_plan_survey_question_id: questionId,
            answer,
          },
        ],
        []
      );
    },
    hasSurveyBeenCompleted(state) {
      return state.survey.status === 'Completed';
    },
  },
  mutations: {
    updateAcknowledgement(state, acknowledgement) {
      state.acknowledgement = acknowledgement;
    },
    updateSurvey(state, survey) {
      state.survey = survey;
    },
    updateQuestions(state, questions) {
      state.questions.allIds = questions.map((question) => question.id);
      state.questions.byId = questions.reduce(
        (questionsById, question) => ({
          ...questionsById,
          [question.id]: question,
        }),
        {}
      );
    },
    updateAnswerByQuestionId(state, { questionId, answer }) {
      state.answers.byQuestionId = {
        ...state.answers.byQuestionId,
        [questionId]: answer,
      };
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
