import router from '@/router';
import RecallQuestionService from '@/services/recall/recall-question.service';
import RecallAnswerService from '@/services/recall/recall-answer.service';
import RecallAnswerTextService from '@/services/recall/recall-answer-text.service';
import RecallAnswerPersonService from '@/services/recall/recall-answer-person.service';
import Vue from 'vue';
import RecallActionPlanService from '@/services/recall/recall-action-plan.service';
import moment from 'moment-timezone';

export default {
  namespaced: true,
  state: {
    isLoading: true,
    questions: [],
    answers: {},
    participating: false,
    textsBeingRemoved: [],
  },
  actions: {
    fetchQuestions({ commit, dispatch }) {
      let params = {
        'q[archived_eq]': false,
      };

      RecallQuestionService.index(params).then((result) => {
        let sortedQuestions = result.data.sort((a, b) => a.order - b.order);
        commit('setQuestions', sortedQuestions);
        dispatch('fetchAnswers', sortedQuestions);
      });
    },
    fetchAnswers({ commit, dispatch }, questions) {
      let answerPromises = [];

      questions.forEach((question) => {
        answerPromises.push(dispatch('fetchOrCreateAnswer', question));
      });

      Promise.all(answerPromises).then(() => {
        commit('setIsLoading', false);
      });
    },
    fetchOrCreateAnswer({ commit, rootState }, question) {
      let params = {
        'q[recall_assessment_id_eq]': rootState.recall.currentAssessment.id,
        'q[recall_question_id_eq]': question.id,
        'q[archived_eq]': false,
      };

      return RecallAnswerService.index(params).then((result) => {
        if (result.data.length > 0) {
          let answer = result.data.find((x) => x !== undefined);
          if (answer) {
            if (answer.completion_date) {
              answer.completion_date = moment(answer.completion_date).format(
                'YYYY-MM-DD'
              );
            }
            commit('setAnswer', {
              answer: answer,
              question_key: question.question_key,
            });
          }
        } else {
          RecallAnswerService.create(params).then((result) => {
            if (result.data) {
              let answer = result.data;
              if (answer) {
                commit('setAnswer', {
                  answer: answer,
                  question_key: question.question_key,
                });
              }
            }
          });
        }
      });
    },
    async updateAllAnswers({ state, dispatch, rootState }) {
      let answerPromises = [];

      for (const [question_key, answer] of Object.entries(state.answers)) {
        answerPromises.push(
          dispatch('updateAnswer', {
            answer: answer,
            question_key: question_key,
          })
        );
      }

      await Promise.all(answerPromises).then(() => {
        let actionPlanParams = {
          recall_assessment_id: rootState.recall.currentAssessment.id,
        };
        RecallActionPlanService.create(actionPlanParams).then(() => {
          router.push({
            name: 'Recall_ActionPlanExport',
            params: {
              dealer_id: rootState.recall.currentDealership?.id ?? null,
              assessment_id: rootState.recall.currentAssessment?.id ?? null,
            },
          });
        });
      });
    },
    async updateAnswer({ commit, dispatch }, { answer, question_key }) {
      let params = {
        answer: answer.answer,
      };
      // only update completion date and texts/people if the answer is yes
      if (answer.answer === true) {
        // completion date
        let compDate = new Date(answer.completion_date + 'T12:00');
        params.completion_date = compDate;
        // answer texts
        if (answer.response_type === 'text') {
          let textPromises = [];

          answer.texts.forEach((text) => {
            textPromises.push(
              dispatch('updateOrCreateAnswerTexts', {
                answer_id: answer.id,
                text: text,
              })
            );
          });

          await Promise.all(textPromises);
        }
        // answer people
        else if (answer.response_type === 'person') {
          let personPromises = [];

          answer.people.forEach((person) => {
            personPromises.push(
              dispatch('updateOrCreateAnswerPeople', {
                answer_id: answer.id,
                person: person,
              })
            );
          });

          await Promise.all(personPromises);
        }
      }

      // answers
      return RecallAnswerService.update(answer.id, params).then((result) => {
        commit('setAnswer', {
          question_key: question_key,
          answer: result.data,
        });
      });
    },
    updateOrCreateAnswerTexts({ state }, { answer_id, text }) { // eslint-disable-line
      if (text.id) {
        return RecallAnswerTextService.update(text.id, { text: text.text });
      } else {
        return RecallAnswerTextService.create({
          recall_answer_id: answer_id,
          text: text.text,
        });
      }
    },
    updateOrCreateAnswerPeople({ state }, { answer_id, person }) { // eslint-disable-line
      if (person.id) {
        return RecallAnswerPersonService.update(person.id, {
          name: person.name,
          email: person.email,
        });
      } else {
        return RecallAnswerPersonService.create({
          recall_answer_id: answer_id,
          name: person.name,
          email: person.email,
        });
      }
    },
    setAnswerBool({ state, commit }, { answer_bool, question_key }) {
      let answer = state.answers[question_key];
      answer.answer = answer_bool;
      commit('setAnswer', { answer: answer, question_key: question_key });
    },
    setAnswerCompletionDate(
      { state, commit },
      { completion_date, question_key }
    ) {
      let answer = state.answers[question_key];
      answer.completion_date = completion_date;
      commit('setAnswer', { answer: answer, question_key: question_key });
    },
    deleteAnswerText({ state, commit }, { question_key, index }) {
      state.textsBeingRemoved.push(index);

      let text = state.answers[question_key]['texts'][index];

      if (text.id) {
        RecallAnswerTextService.destroy(text.id).then(() => {
          commit('removeAnswerText', {
            question_key: question_key,
            index: index,
          });
          commit('removeTextsBeingRemovedIndex', index);
        });
      } else {
        commit('removeAnswerText', {
          question_key: question_key,
          index: index,
        });
        commit('removeTextsBeingRemovedIndex', index);
      }
    },
  },
  getters: {
    getAnswerBool: (state) => (question_key) => {
      return state.answers[question_key].answer;
    },
    getAnswerCompletionDate: (state) => (question_key) => {
      return state.answers[question_key].completion_date;
    },
    getCanCreateSuggestion: (state) => (question_key) => {
      return state.answers[question_key].texts.length < 10;
    },
  },
  mutations: {
    setQuestions(state, questions) {
      state.questions = questions;
    },
    setAnswer(state, payload) {
      Vue.set(state.answers, payload['question_key'], payload['answer']);
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setParticipation(state) {
      let answers = Object.values(state.answers).map((answer) => answer.answer);
      state.participating = !!answers.includes(true);
    },
    addAnswerText(state, question_key) {
      state.answers[question_key]['texts'].push({ text: '' });
      state.answers = { ...state.answers };
    },
    removeAnswerText(state, payload) {
      state.answers[payload['question_key']]['texts'].splice(
        payload['index'],
        1
      );
      state.answers = { ...state.answers };
    },
    removeTextsBeingRemovedIndex(state, index) {
      let indexOf = state.textsBeingRemoved.indexOf(index);
      state.textsBeingRemoved = state.textsBeingRemoved.slice(indexOf, indexOf);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
