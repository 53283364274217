import ApiService from './api.service';

export const ActionPlanSurveyService = {
  getOrCreate(assessmentId) {
    return ApiService.query(`model_e/assessments/${assessmentId}/survey`);
  },
  addSurveyAnswers(assessmentId, data) {
    return ApiService.post(`model_e/assessments/${assessmentId}/survey`, data);
  },
};

export default ActionPlanSurveyService;
