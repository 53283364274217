export default {
  namespaced: true,
  state: {
    currentUser: null,
  },
  mutations: {
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
