import router from '@/router';
import DealerService from '@/services/dealer.service';
import RecallAssessmentService from '@/services/recall/recall-assessment.service';
import RecallActionPlanService from '@/services/recall/recall-action-plan.service';

export default {
  namespaced: true,
  state: {
    currentAssessment: null,
    currentDealership: null,
    actionPlans: null,
    failedSubmit: false,
  },
  actions: {
    async getDealershipById({ commit }, dealershipId) {
      const response = await DealerService.show(dealershipId);
      commit('setCurrentDealership', response.data);
    },
    async getAssessmentById({ commit }, assessmentId) {
      const response = await RecallAssessmentService.show(assessmentId);
      commit('setCurrentAssessment', response.data);
    },
    async updateAssessment({ commit, state, dispatch, rootState }) {
      // rootState.recallQuestions.isLoading = true;
      const params = {
        participating: rootState.recallQuestions.participating,
        notes: state.currentAssessment.notes,
        rejection_reason: state.currentAssessment.rejection_reason,
      };
      const response = await RecallAssessmentService.update(
        state.currentAssessment.id,
        params
      );

      commit('setCurrentAssessment', response.data);
      dispatch('recallQuestions/updateAllAnswers', null, { root: true });
    },
    async fetchActionPlans({ state, commit }) {
      let params = {
        'q[recall_assessment_id_eq]': state.currentAssessment.id,
        'q[archived_eq]': false,
      };

      const response = await RecallActionPlanService.index(params);
      commit('setActionPlans', response.data);
    },
  },
  getters: {},
  mutations: {
    setCurrentAssessment(state, assessment) {
      state.currentAssessment = assessment;
    },
    setCurrentDealership(state, dealership) {
      if (state.currentDealership !== null && dealership === null) {
        router.push({ name: 'ModelE_DealershipSelection' });
      }
      state.currentDealership = dealership;
    },
    setActionPlans(state, action_plans) {
      state.actionPlans = action_plans;
    },
    setFailedSubmit(state, status) {
      state.failedSubmit = status;
    },
  },
  strict: process.env.NODE_ENV !== 'production',
};
