import ApiService from './api.service';

export const ModelEComplianceMetricAnswerMeasureService = {
  index(params) {
    return ApiService.query('model_e/compliance_metric_answer_measures', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('model_e/compliance_metric_answer_measures', slug);
  },
  create(params) {
    return ApiService.post('model_e/compliance_metric_answer_measures', {
      data: params,
    });
  },
  update(slug, params) {
    return ApiService.update(
      'model_e/compliance_metric_answer_measures',
      slug,
      { data: params }
    );
  },
  destroy(slug) {
    return ApiService.delete('model_e/compliance_metric_answer_measures', slug);
  },
};

export default ModelEComplianceMetricAnswerMeasureService;
