import ApiService from './api.service';

export const ModelEComplianceActionPlanService = {
  index(params) {
    return ApiService.query('model_e/compliance_action_plans', {
      params,
    });
  },
  show(slug) {
    return ApiService.get('model_e/compliance_action_plans', slug);
  },
  create(params) {
    return ApiService.post('model_e/compliance_action_plans', { data: params });
  },
  update(slug, params) {
    return ApiService.update('model_e/compliance_action_plans', slug, {
      data: params,
    });
  },
  destroy(slug) {
    return ApiService.delete('model_e/compliance_action_plans', slug);
  },
};

export default ModelEComplianceActionPlanService;
