import ApiService from './api.service';
import JwtService from './jwt.service';

const AuthService = {
  login(email, password, redirect = '/') {
    ApiService.unsetHeader();
    return ApiService.directPost('users/sign_in', { user: { email, password } })
      .then(({ data }) => {
        // console.log(data);
        this.registerUserSession(data);
        return redirect;
      })
      .catch((response) => {
        throw new Error(`AuthService ${response}`);
      });
  },
  logout(clearLocalStorage = true) {
    JwtService.destroyToken();
    if (clearLocalStorage) {
      localStorage.clear(); // need to use localstorage to save some data
    }
    ApiService.unsetHeader();
  },
  checkAuth(to, from, next) {
    if (to.name !== 'Login') {
      if (JwtService.getToken() && !JwtService.isTokenExpired()) {
        // I like using variables because it's easier to read and is a single place to change later
        const userCountry = JwtService.getUserCountry();
        const userRoles = JwtService.getRole();
        if (userRoles) {
          const parsedUserRoles = userRoles.split(',');
          let isElevated = false;
          let isAdmin = false;
          let isCorporate = false;
          let isModelECorporate = false;
          let isEmployee = false;
          let isDealer = false;

          // Let's check for what roles they have that we know about
          if (parsedUserRoles.includes('admin')) {
            isAdmin = true;
          }
          if (parsedUserRoles.includes('corporate')) {
            isCorporate = true;
          }
          if (parsedUserRoles.includes('modele_corporate')) {
            isModelECorporate = true;
          }
          if (isAdmin || isCorporate) {
            isElevated = true;
          }
          if (parsedUserRoles.includes('employee')) {
            isEmployee = true;
          }
          if (parsedUserRoles.includes('dealer')) {
            isDealer = true;
          }

          // Dealers are quite restricted to their movements, only these routes
          const allowedDealerRoutesUS = [
            'DealerLanding',
            'ModelE_ActionPlanSurvey',
          ];
          const allowedDealerRoutesCAN = [
            'ModelE_ActionPlanExportHistory',
            'ModelE_ModelEAssessment',
            'ModelE_DealershipSelection',
            'ModelE_Instructions',
            'ModelE_ModelE',
            'ModelEAssessment',
            'DealerLanding',
            'ModelE_ActionPlanSurvey',
          ];
          // const rolesData = {
          //   userRoles: userRoles,
          //   isElevated: isElevated,
          //   isAdmin: isAdmin,
          //   isCorporate: isCorporate,
          //   isEmployee: isEmployee,
          //   isDealer: isDealer,
          //   parsedUserRoles: parsedUserRoles,
          // };
          // console.log('auth.service -> rolesData', rolesData);

          let routeParams = {};

          if (isElevated) {
            routeParams = {};
            if (
              to.name === 'ModelE_Reports' &&
              !isModelECorporate &&
              !isAdmin
            ) {
              routeParams = { name: 'ModelEAssessment' };
            }
          } else if (isEmployee) {
            if (to.name === 'ModelE_Reports') {
              routeParams = { name: 'ModelEAssessment' };
            } else if (to.name === 'Recall_Reports') {
              routeParams = { name: 'RecallAssessment' };
            }
          } else if (isDealer) {
            if (userCountry === 'Canada') {
              if (!allowedDealerRoutesCAN.includes(to.name)) {
                routeParams = { name: 'DealerLanding' };
              }
            } else {
              if (!allowedDealerRoutesUS.includes(to.name)) {
                routeParams = { name: 'DealerLanding' };
              }
            }
          }

          ApiService.setHeader();
          next(routeParams);
        } else {
          // console.log("no way you get in");
          this.logout(false);
          next({ name: 'Login', query: { redirect: to.fullPath } });
        }
      } else {
        // console.log("no way you get in");
        this.logout(false);
        next({ name: 'Login', query: { redirect: to.fullPath } });
      }
    } else {
      // console.log("go ahead");
      // console.log(to.name);
      next();
    }
  },
  registerUserSession(data) {
    //get user's most recent role
    const roles = [];
    for (let i = 0; i < data.roles.length; i += 1) {
      let role = data.roles[i];
      if (role) {
        roles.push(role.name);
      }
    }
    // console.log('auth.service -> data', data);
    // console.log('auth.service -> roles', roles);
    // let role = data.roles
    //   .sort(function (a, b) {
    //     return new Date(b.created_at) - new Date(a.created_at);
    //   })
    //   .find((x) => x !== undefined).name;
    JwtService.saveRole(roles);
    JwtService.saveToken(data.token);
    JwtService.saveUserId(data.authenticatable_id);
    JwtService.saveUserCountry(data.country);
    ApiService.setHeader();
  },
};

export default AuthService;
